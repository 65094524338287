import React from 'react';
import { Result } from 'antd';
import {  } from '@ant-design/icons';

const Global = props => {

    return (
        <Result
            className="wowload"
            status="404"
            title="Упс"
            subTitle="Для прохождения опроса нужно иметь приглашение"
        />
    )
};

export default Global;